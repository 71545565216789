import React , {useEffect} from 'react'

function AdComponentSquare() {
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }, []);
    
  return (
    <ins class="adsbygoogle"
    style={{ display: "block", width: "300px", height: "250px" }}
     data-ad-client="ca-pub-9423830303908191"
     data-ad-slot="4278598613"></ins>

  )
}

export default AdComponentSquare