import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Game1 from "../../Assets/image/game1.png";
import Game2 from "../../Assets/image/game2.png";
import Game3 from "../../Assets/image/game3.jpeg";
import Game4 from "../../Assets/image/game4.png";
import Game5 from "../../Assets/image/game5.png";
import Game6 from "../../Assets/image/game6.jpeg";
import Game7 from "../../Assets/image/game7.jpeg";
import Game8 from "../../Assets/image/game8.jpeg";
import Game9 from "../../Assets/image/game9.png";
import Game10 from "../../Assets/image/game10.jpeg";
import Game11 from "../../Assets/image/game11.jpeg";
import Game12 from "../../Assets/image/game12.jpeg";
import Game13 from "../../Assets/image/game13.jpeg";
import Game14 from "../../Assets/image/game14.png";
import Game15 from "../../Assets/image/game15.png";
import Game16 from "../../Assets/image/game16.png";
import banner1 from "../../Assets/image/banar1.png";
import banner2 from "../../Assets/image/banar2.png";
import { Button, Grid, Modal, useMediaQuery } from "@mui/material";
import GameFrame from "../GameCanvas";
import CloseIcon from "@material-ui/icons/Close";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import AdComponentSquare from "../AdSenese/AdComponentSquare";

const Dashboard = () => {
  const baseUrl = window.location.origin;
  const [gameList, setGameList] = useState([
    {
      imageUrl: Game1,
      gameUrlName: "2048Cube",
      gameName: "2048 Cube",
      gameUrl: "https://ocee-game.s3.ap-south-1.amazonaws.com/game1/index.html",
    },
    {
      imageUrl: Game2,
      gameUrlName: "Knifegame",
      gameName: "Knife game",
      // gameUrl: "Knife-Game-WEBGL-/index.html",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game2/Knife-Game-WEBGL-/index.html",
    },
    {
      imageUrl: Game3,
      gameUrlName: "DonutsCandy",
      gameName: "Donuts Candy",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game3/Donuts Full/Donuts-candy/donuts-candy-game.html",
    },
    {
      imageUrl: Game4,
      gameUrlName: "Farmstory",
      gameName: "Farm story",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game4/Farm-Story/farm-story-game.html",
    },
    {
      imageUrl: Game5,
      gameUrlName: "2048Cube",
      gameName: "2048 Cube",
      gameName: "Formula car drift ride",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game5/formula-drag/HTML5/formula-drag.html",
    },
    {
      imageUrl: Game6,
      gameUrlName: "FrozenWinter",
      gameName: "Frozen Winter",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game6/Frozen-Winter/frozen-winter.html",
    },
    {
      imageUrl: Game7,
      gameUrlName: "HellowenStory",
      gameName: "Hellowen Story",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game7/Halloween Story/halloween-game.html",
    },
    {
      imageUrl: Game8,
      gameUrlName: "Candyfrenzy",
      gameName: "Candy frenzy",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game8/Ice Cream Frenzy/ice-cream-frenzy.html",
    },
    {
      imageUrl: Game9,
      gameUrlName: "ShantaMisson",
      gameName: "Shanta’s Misson",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game9/Santas Mission Full/Santa's Mission/santa-mission.html",
    },
    {
      imageUrl: Game10,
      gameUrlName: "2048",
      gameName: "2048",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game10/sliding_puzzle-master/sliding_puzzle-game.html",
    },
    {
      imageUrl: Game11,
      gameUrlName: "SpaceStory",
      gameName: "Space Story",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game11/Space-Adventure/space-adventure.html",
    },
    {
      imageUrl: Game12,
      gameUrlName: "TowerBuild",
      gameName: "Tower Build",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game12/tower_game-master/tower-game-master.html",
    },
    {
      imageUrl: Game13,
      gameUrlName: "Carwrecked",
      gameName: "Car wrecked",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game13/wrecked/HTML5/wrecked.html",
    },
    {
      imageUrl: Game14,
      gameUrlName: "ballvsbricks",
      gameName: "ball vs bricks",
      // gameUrl: "Ball breack WEBGL/index.html",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game14/index.html",
    },
    {
      imageUrl: Game15,
      gameUrlName: "2048puzzle",
      gameName: "2048 puzzle",
      // gameUrl: "NEW Box Puzzle WEB/index.html",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game15/index.html",
    },
    {
      imageUrl: Game16,
      gameUrlName: "penguinsling",
      gameName: "penguin sling",
      gameUrl:
        "https://ocee-game.s3.ap-south-1.amazonaws.com/game16/puppysling+webgl/index.html",
      // "puppysling webgl/index.html",
    },
  ]);

  const [advertiesment, setAdvertiesment] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const navigate = useNavigate();

  const handleGameSelect = (gameUrl, gameUrlName, gameName) => {
    ReactGA.event({
      category: gameUrlName,
      action: "Game Action",
      label: "Game Label",
      value: gameName,
    });
    localStorage.setItem("selectedGame", gameUrl);
    localStorage.setItem("selectedGameName", gameUrlName);
    setSelectedGame(gameUrl);

    if (gameUrl) {
      navigate(`/games?${gameUrlName}`);
    }
  };

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const chunkedGameList = chunkArray(gameList, 4);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  useEffect(() => {
    // Your logic to determine when to show the advertisement
    // For example, after fetching advertisement data or based on user interactions
    // For now, let's just simulate it being shown after 3 seconds
    const timeout = setTimeout(() => {
      setAdvertiesment(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <section
      // className="home-section"
      // style={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
    >
      <div className="container">
        {advertiesment && (
          <Grid container justifyContent="center">
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className="sub-container  d-flex justify-content-center align-items-center"
              style={{
                padding: "15px",
                color: "black",
                backgroundColor: "transparent",
                border : 'none',
                height: "300px",
                width: "250px",
              }}
            >
              <AdComponentSquare />
            </Grid>
          </Grid>
        )}
      </div>
      <div className="container">
        {/* <Grid container justifyContent="center"> */}
        {/* {!selectedGame && ( */}
        <Grid container justifyContent="center" className="">
          {/* <Grid item lg={9} md={9} className="sub-container my-4 p-sm-4 p-2">
            <Grid container spacing={4}>
              {gameList.map((game, index) => (
                <Grid item lg={3} md={3} xs={6} sm={4} key={index}>
                  <div
                    onClick={() => {
                      setSelectedGame(game?.gameUrl);
                      handleGameSelect(game?.gameUrl);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={game.imageUrl}
                      alt={game.gameName}
                      style={{ width: "100%", borderRadius: "10px" }}
                    />
                    <div
                      style={{
                        color: "#FFFFFF",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      {game.gameName}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid> */}
          {chunkedGameList.map((chunk, index) => (
            <React.Fragment key={index}>
              <Grid
                item
                lg={10}
                md={10}
                className="sub-container mt-3 mb-3  p-2"
                style={{ backgroundColor: "#ffffff", borderRadius: "15px" }}
              >
                <Grid container spacing={4}>
                  {chunk.map((game, index) => (
                    <Grid item lg={6} md={6} xs={6} sm={6} key={index}>
                      <div
                        onClick={() => {
                          setSelectedGame(game?.gameUrl);
                          handleGameSelect(
                            game?.gameUrl,
                            game.gameUrlName,
                            game.gameName
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={game.imageUrl}
                          alt={game.gameName}
                          style={{ width: "100%", borderRadius: "10px" }}
                        />
                        <div
                          style={{
                            color: "black",
                            fontSize: "20px",
                            fontWeight: "600",
                            textAlign: "center",
                            marginTop: "10px",
                          }}
                        >
                          {game.gameName}
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {/* {index % 2 === 1 && ( // Insert advertisement after every two rows
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={2}
                  md={2}
                  className="sub-container my-4 p-sm-4 p-2"
                >
                  <div
                    className="d-flex justify-content-center align-items-center flex-column"
                    style={{ color: "white" }}
                  >
                    ADVERTISEMENT
                  </div>
                </Grid>
              )}*/}
              {/* Insert an AdComponentSquare between Santa's Mission and Crazy Frendy */}
              {index === Math.ceil(chunkedGameList.length / 2) - 1 &&
                advertiesment && (
                 <Grid container justifyContent="center">
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className="sub-container d-flex justify-content-center align-items-center"
              style={{
                padding: "15px",
                color: "black",
                backgroundColor: "transparent",
                border : 'none',
                height: "300px",
                width: "250px",
              }}
            >
              <AdComponentSquare />
            </Grid>
          </Grid>
                )}
            </React.Fragment>
          ))}
          <Grid
            item
            lg={10}
            md={10}
            className="sub-container mt-3 mb-3 p-sm-4 p-2"
            style={{ backgroundColor: "#ffffff", borderRadius: "15px" }}
          >
            <Grid container spacing={4}>
              {/* First Item */}
              <Grid item lg={6} md={6} xs={6} sm={6}>
                {" "}
                <div
                  onClick={() =>
                    window.open(
                      "https://8585.play.gamezop.com/?int-nav=1",
                      "_blank"
                    )
                  }
                 
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={banner1}
                    alt="banner"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </div>
              </Grid>

              {/* Second Item */}
              <Grid item lg={6} md={6} xs={6} sm={6}>
                {" "}
                <div
                  onClick={() =>
                    window.open(
                      "https://8697.play.quizzop.com/",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={banner2}
                    alt="banner"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* } */}
          {/* {selectedGame && (
          <GameFrame
            gameUrl={selectedGame}
            onClose={handleCloseGame}
            setSelectedGame={setSelectedGame}
          />
        )} */}
          {/* <Grid
            item
            xs={12}
            sm={12}
            lg={2}
            md={2}
            sx={{
              ...(isSmallScreen ? {} : { ml: 10 }),
            }}
            className="sub-container my-4 p-sm-4 p-2"
            // className="offset-1 col-lg-2 col-md-2 sub-container my-4 p-sm-4 p-2"
          >
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ color: "white" }}
            >
              ADVERTISEMENT
            </div>
          </Grid> */}
          {advertiesment && (
          <Grid container justifyContent="center">
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className="sub-container d-flex justify-content-center align-items-center"
              style={{
                padding: "15px",
                color: "black",
                backgroundColor: "transparent",
                border : 'none',
                height: "300px",
                width: "250px",
              }}
            >
              <AdComponentSquare />
            </Grid>
          </Grid>
        )}
        </Grid>
      </div>
    </section>
  );
};

export default Dashboard;
