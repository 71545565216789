import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import AdComponent from "../Pages/AdSenese/AdComponent";

const Layout = ({ children }) => {
  
  const backgroundStyle = {
    backgroundImage: `url('https://fecdn.mglgamez.com/assets/images/webappbdg-op.jpg')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const layoutStyle = {
    maxWidth: "400px",
    margin: "0px auto",
    backgroundColor: "#6c757d",
    overflowY: "auto",
    overflowX: "hidden",
    height: "100vh",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    position: "relative",
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <div style={backgroundStyle}>
      <div style={layoutStyle}>
        <Header />
        {children}
        <Footer />
        <div
          style={{
            maxWidth: "400px",
            width: "100%",
            overflow: 'hidden',
            backgroundColor: "white",
            position: "fixed",
            bottom: 0,
            maxHeight: "60px", 
          }}
        >
          <AdComponent client={"ca-pub-9423830303908191"} adSlot={"7951385787"} />
        </div>
      </div>
    </div>
  );
};

export default Layout;
