import React, { useEffect, useState } from "react";

const AdComponent = ({ client, adSlot }) => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  //   useEffect(() => {
  //     console.log("window " , window)
  //     const timer = setTimeout(() => {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     }, 1000); // Delay loading by 1 second
  //     return () => clearTimeout(timer);
  //   }, []);

  // const [adLoaded, setAdLoaded] = useState(false);

  // useEffect(() => {
  //   if (!adLoaded) {
  //     (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     setAdLoaded(true);
  //   }
  // }, [adLoaded]);
  return (
    <ins
    className="adsbygoogle"
    style={{ display: "block", width: "320px", height: "50px" }}
    data-ad-client={client}
    data-ad-slot={adSlot}
  ></ins>

  );
};

export default AdComponent;
